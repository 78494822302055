/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you require will output into a single css file (app.css in this case)
import './styles/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
import * as $ from 'jquery';

// const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('datatables.net-bs4')( $ );
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

// const initAutocomplete = require('./js/geolocation.js');

$(function() {
    $('[data-toggle="popover"]').popover();
	
	$.extend( $.fn.dataTableExt.oSort, {
		"date-euro-pre": function ( a ) {
			var x;
	
			if ( a.trim() !== '' ) {
				var frDatea = a.trim().split(' ');
				var frTimea = (undefined != frDatea[1]) ? frDatea[1].split(':') : ['00','00','00'];
				var frDatea2 = frDatea[0].split('/');
				x = (frDatea2[2] + frDatea2[1] + frDatea2[0] + frTimea[0] + frTimea[1] + ((undefined != frTimea[2]) ? frTimea[2] : 0)) * 1;
			}
			else {
				x = Infinity;
			}
	
			return x;
		},
	
		"date-euro-asc": function ( a, b ) {
			return a - b;
		},
	
		"date-euro-desc": function ( a, b ) {
			return b - a;
		}
	});
	
	$("#dataTable").DataTable({
		"language": {
			"url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json"
		},
		"columnDefs": [
			{ type: 'date-euro', targets: 1 }
		],
		"order": [[
			1, "desc"
		]]
	});
	
	if ($('.mdtextarea').length) {
		var editor = new Editor({
		});

		editor.render();
	}
	
	var category = $('#listing_category');
	$("#listing_category").on('change', function() {
		console.log($(this).find("option:checked").text());
		if($(this).find("option:checked").text() == 'Koordinationsstelle') {
			$('#listing_export').parent().hide();
			$('#listing_tags').parent().hide();
		} else {
			$('#listing_export').parent().show();
			$('#listing_tags').parent().show();
		}
	});
	// When category gets selected ...
	category.on('change', function() {
		// ... retrieve the corresponding form.
		var $form = $(this).closest('form');
		// Simulate form data, but only include the selected category value.
		var data = {};
		data[category.attr('name')] = category.val();
		// Submit data via AJAX to the form's action path.
		$.ajax({
			url : $form.attr('action'),
			type: $form.attr('method'),
			data : data,
			success: function(html) {
				// Replace current tags ...
				$('#listing_tags').replaceWith(
					// ... with the returned one from the AJAX response.
					$(html).find('#listing_tags')
				);
				// Tags now displays the appropriate tags.
			},

		});
	});




	// Get the ul that holds the collection of tags
	var $areaCodesCollectionHolder = $('div.areaCodes');
	// count the current form inputs we have (e.g. 2), use that as the new
	// index when inserting a new item (e.g. 2)
	$areaCodesCollectionHolder.data('index', $areaCodesCollectionHolder.find('input').length);

	$('body').on('click', '.add_item_link', function(e) {
		var $collectionHolderClass = $(e.currentTarget).data('collectionHolderClass');
		// add a new tag form (see next code block)
		addFormToCollection($collectionHolderClass);
	})
	// add a delete link to all of the existing tag form li elements
	$areaCodesCollectionHolder.find('div').each(function() {
		addTagFormDeleteLink($(this));
	});
});


function addFormToCollection($collectionHolderClass) {
	// Get the ul that holds the collection of tags
	var $collectionHolder = $('.' + $collectionHolderClass);

	// Get the data-prototype explained earlier
	var prototype = $collectionHolder.data('prototype');

	// get the new index
	var index = $collectionHolder.data('index');

	var newForm = prototype;
	// You need this only if you didn't set 'label' => false in your tags field in TaskType
	// Replace '__name__label__' in the prototype's HTML to
	// instead be a number based on how many items we have
	// newForm = newForm.replace(/__name__label__/g, index);

	// Replace '__name__' in the prototype's HTML to
	// instead be a number based on how many items we have
	newForm = newForm.replace(/__name__/g, index);

	// increase the index with one for the next item
	$collectionHolder.data('index', index + 1);

	// Display the form in the page in an li, before the "Add a tag" link li
	var $newFormLi = $('<div class="input-group form-group"></div>').append(newForm);
	// Add the new form at the end of the list
	$collectionHolder.append($newFormLi);
	// add a delete link to the new form
	addTagFormDeleteLink($newFormLi);
}

function addTagFormDeleteLink($tagFormLi) {
	var $removeFormButton = $('<div class="input-group-append"><button type="button" class="btn btn-secondary">PLZ entfernen</button></div>');
	$tagFormLi.append($removeFormButton);

	$removeFormButton.on('click', function(e) {
		// remove the li for the tag form
		$tagFormLi.remove();
	});
}
